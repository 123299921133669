@charset "UTF-8";

:root {
    --bulma-primary-rgb: 102, 0, 0;
    --bulma-primary-h: 0deg;
    --bulma-primary-s: 100%;
    --bulma-primary-l: 20%;
    --bulma-info-rgb: 251, 205, 158;
    --bulma-info-h: 30deg;
    --bulma-info-s: 92%;
    --bulma-info-l: 80%;
}

.content {
    --bulma-content-table-cell-border: 1px solid hsl(0, 100%, 20%)
}